<template lang="pug">
section.main
  //- aside.side-menu(v-if="$auth.loggedIn")
  aside.side-menu
    Sidemenu
  main.core
    //- Activeteam
    Tabmenu(:tabs="items")
    slot
</template>

<script setup>
import { useTeamStore } from "~/stores/team";
const teamStore = useTeamStore();
await teamStore.getTeams();
await teamStore.getActiveTeam();
// await teamStore.getTeamInvites();

const items = ref([
  {
    label: "Account & Team",
    link: "/account/",
  },
  {
    label: "Manage Plan & Billing",
    link: "/account/plan/",
  },
  {
    label: "Account Settings",
    link: "/account/settings/",
  },
]);
const activeItem = ref(1);
</script>
<style lang="scss">
section.main {
  overflow-x: hidden;
  display: flex;
  width: 100%;
  min-height: 100vh;
  .side-menu {
    // flex: 1;
    width: rempx(91);
  }
  .core {
    flex: 1;
  }
}
</style>
